import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Distractor from './components/Distractor';
// Navbar
const Home = lazy(() => import('./routes/Home/Home'));
const About = lazy(() => import('./routes/About/About'));
const Games = lazy(() => import('./routes/Games/Games'));
const Contact = lazy(() => import('./routes/Contact/Contact'));
// Coming Soon
const PrivacyPolicy = lazy(() => import('./routes/PrivacyPolicy/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./routes/TermsAndConditions/TermsAndConditions'));
// Games
const MightyZeus = lazy(() => import('./routes/Games/Game/MightyZeus/MightyZeus'));
const BrainBusters = lazy(() => import('./routes/Games/Game/BrainBusters/BrainBusters'));
const PyramidsOfCashRB = lazy(() => import('./routes/Games/Game/PyramidsOfCashRB/PyramidsOfCashRB'));
const LostInTheWildRB = lazy(() => import('./routes/Games/Game/LostInTheWildRB/LostInTheWildRB'));
const Reels2RichesRB = lazy(() => import('./routes/Games/Game/Reels2RichesRB/Reels2RichesRB'));
const WildLionRB = lazy(() => import('./routes/Games/Game/WildLionRB/WildLionRB'));
const JungleFeverSuperReels = lazy(() => import('./routes/Games/Game/JungleFeverSuperReels/JungleFeverSuperReels'));
const JungleFeverV2 = lazy(() => import('./routes/Games/Game/JungleFeverV2/JungleFeverV2'));
const ReadyYetiRespin = lazy(() => import('./routes/Games/Game/ReadyYetiRespin/ReadyYetiRespin'));
const PocoLoco = lazy(() => import('./routes/Games/Game/PocoLoco/PocoLoco'));
const MasterOfElements = lazy(() => import('./routes/Games/Game/MasterOfElements/MasterOfElements'));
const JungleHunt = lazy(() => import('./routes/Games/Game/JungleHunt/JungleHunt'));
const RoadTrip = lazy(() => import('./routes/Games/Game/RoadTrip/RoadTrip'));
const TikiBar = lazy(() => import('./routes/Games/Game/TikiBar/TikiBar'));
const LuckyTurtle = lazy(() => import('./routes/Games/Game/LuckyTurtle/LuckyTurtle'));
const TwentyThousandLeagues = lazy(() => import('./routes/Games/Game/TwentyThousandLeagues/TwentyThousandLeagues'));
const TwoCanPartySuperReels = lazy(() => import('./routes/Games/Game/TwoCanPartySuperReels/TwoCanPartySuperReels'));
const FastFortune = lazy(() => import('./routes/Games/Game/FastFortune/FastFortune'));
const DeepSeaDollarsRB = lazy(() => import('./routes/Games/Game/DeepSeaDollarsRB/DeepSeaDollarsRB'));
const DeepSeaDollarsSnL = lazy(() => import('./routes/Games/Game/DeepSeaDollarsSnL/DeepSeaDollarsSnL'));
const JungleJive = lazy(() => import('./routes/Games/Game/JungleJive/JungleJive'));
const StickyFeet = lazy(() => import('./routes/Games/Game/StickyFeet/StickyFeet'));
const ReadyYetiGo = lazy(() => import('./routes/Games/Game/ReadyYetiGo/ReadyYetiGo'));
const TheAwakening = lazy(() => import('./routes/Games/Game/TheAwakening/TheAwakening'));
const TheBigFive = lazy(() => import('./routes/Games/Game/TheBigFive/TheBigFive'));
const RomeoAndJuliet = lazy(() => import('./routes/Games/Game/RomeoAndJuliet/RomeoAndJuliet'));
const Tarzan = lazy(() => import('./routes/Games/Game/Tarzan/Tarzan'));
const LobsterPots = lazy(() => import('./routes/Games/Game/LobsterPots/LobsterPots'));
const RiseOfThePharaohs = lazy(() => import('./routes/Games/Game/RiseOfThePharaohs/RiseOfThePharaohs'));
const RaidersOfTheSea = lazy(() => import('./routes/Games/Game/RaidersOfTheSea/RaidersOfTheSea'));
const WildLionSuperReels = lazy(() => import('./routes/Games/Game/WildLionSuperReels/WildLionSuperReels'));
const BlaznBuffalo = lazy(() => import('./routes/Games/Game/BlaznBuffalo/BlaznBuffalo'));
const GoldenSabre = lazy(() => import('./routes/Games/Game/GoldenSabre/GoldenSabre'));
const SpinCarnival = lazy(() => import('./routes/Games/Game/SpinCarnival/SpinCarnival'));
const GoldDiggin = lazy(() => import('./routes/Games/Game/GoldDiggin/GoldDiggin'));
const TwoCanParty = lazy(() => import('./routes/Games/Game/TwoCanParty/TwoCanParty'));
const StackedCats = lazy(() => import('./routes/Games/Game/StackedCats/StackedCats'));
const WayOfTheTiger = lazy(() => import('./routes/Games/Game/WayOfTheTiger/WayOfTheTiger'));
const JackpotHits = lazy(() => import('./routes/Games/Game/JackpotHits/JackpotHits'));
const MightyMedusa = lazy(() => import('./routes/Games/Game/MightyMedusa/MightyMedusa'));
const PowerPets = lazy(() => import('./routes/Games/Game/PowerPets/PowerPets'));
const NightInEgypt = lazy(() => import('./routes/Games/Game/NightInEgypt/NightInEgypt'));
const ItsAlive = lazy(() => import('./routes/Games/Game/ItsAlive/ItsAlive'));
const DanceOfTheDead = lazy(() => import('./routes/Games/Game/DanceOfTheDead/DanceOfTheDead'));
const GoldenElephant = lazy(() => import('./routes/Games/Game/GoldenElephant/GoldenElephant'));
const SirenOfTheDeep = lazy(() => import('./routes/Games/Game/SirenOfTheDeep/SirenOfTheDeep'));
const MoonlightPanda = lazy(() => import('./routes/Games/Game/MoonlightPanda/MoonlightPanda'));
const RoyalDragon = lazy(() => import('./routes/Games/Game/RoyalDragon/RoyalDragon'));
const MontezumasQuest = lazy(() => import('./routes/Games/Game/MontezumasQuest/MontezumasQuest'));
const WolfPack = lazy(() => import('./routes/Games/Game/WolfPack/WolfPack'));
const WinningBull = lazy(() => import('./routes/Games/Game/WinningBull/WinningBull'));
const UnicornDreams = lazy(() => import('./routes/Games/Game/UnicornDreams/UnicornDreams'));
const TheLastDynasty = lazy(() => import('./routes/Games/Game/TheLastDynasty/TheLastDynasty'));
const AfricanRhino = lazy(() => import('./routes/Games/Game/AfricanRhino/AfricanRhino'));
const JackAndTheGiant = lazy(() => import('./routes/Games/Game/JackAndTheGiant/JackAndTheGiant'));
const HappyLucky = lazy(() => import('./routes/Games/Game/HappyLucky/HappyLucky'));
const ThunderingHearts = lazy(() => import('./routes/Games/Game/ThunderingHearts/ThunderingHearts'));
const GoldTrain = lazy(() => import('./routes/Games/Game/GoldTrain/GoldTrain'));
const Cosmonaut = lazy(() => import('./routes/Games/Game/Cosmonaut/Cosmonaut'));
const SinbadsFortune = lazy(() => import('./routes/Games/Game/SinbadsFortune/SinbadsFortune'));
const SkyPirates = lazy(() => import('./routes/Games/Game/SkyPirates/SkyPirates'));
const TheLostIdol = lazy(() => import('./routes/Games/Game/TheLostIdol/TheLostIdol'));
const RiverNBlues = lazy(() => import('./routes/Games/Game/RiverNBlues/RiverNBlues'));
const MoreCandy = lazy(() => import('./routes/Games/Game/MoreCandy/MoreCandy'));
const NavajoLegend = lazy(() => import('./routes/Games/Game/NavajoLegend/NavajoLegend'));
const DolphinBay = lazy(() => import('./routes/Games/Game/DolphinBay/DolphinBay'));

const Router = (
	<Suspense fallback={ <Distractor /> }>
		<Routes>
			{/* Navbar */}
			<Route exact path='/' element={ <Home /> } />
			<Route exact path='/about' element={ <About /> } />
			<Route exact path='/games' element={ <Games /> } />
			<Route exact path='/contact' element={ <Contact /> } />

            {/* Coming Soon */}
			<Route exact path='/privacy' element={ <PrivacyPolicy /> } />
			<Route exact path='/terms' element={ <TermsAndConditions /> } />

            {/* Games */}
			<Route exact path='/games/mightyzeus' element={<MightyZeus />} />
			<Route exact path='/games/brainbusters' element={<BrainBusters />} />
            <Route exact path='/games/pyramidsofcashrb' element={<PyramidsOfCashRB />} />
            <Route exact path='/games/lostinthewildrb' element={<LostInTheWildRB />} />
            <Route exact path='/games/reels2richesrb' element={<Reels2RichesRB />} />
            <Route exact path='/games/wildlionrb' element={<WildLionRB />} />
			<Route exact path='/games/pocoloco' element={ <PocoLoco /> } />
			<Route exact path='/games/readyyetirespin' element={ <ReadyYetiRespin /> } />
			<Route exact path='/games/junglefeversuperreels' element={ <JungleFeverSuperReels /> } />
			<Route exact path='/games/junglefeverv2' element={ <JungleFeverV2 /> } />
			<Route exact path='/games/masterofelements' element={ <MasterOfElements /> } />
			<Route exact path='/games/junglehunt' element={ <JungleHunt /> } />
			<Route exact path='/games/roadtrip' element={ <RoadTrip /> } />
			<Route exact path='/games/tikibar' element={ <TikiBar /> } />
			<Route exact path='/games/dolphinbay' element={ <DolphinBay/> } />
			<Route exact path='/games/luckyturtle' element={ <LuckyTurtle /> } />
			<Route exact path='/games/twentythousandleagues' element={ <TwentyThousandLeagues /> } />
			<Route exact path='/games/twocanpartysuperreels' element={ <TwoCanPartySuperReels /> } />
			<Route exact path='/games/fastfortune' element={ <FastFortune /> } />
			<Route exact path='/games/deepseadollarssnl' element={ <DeepSeaDollarsSnL /> } />
			<Route exact path='/games/deepseadollarsrb' element={ <DeepSeaDollarsRB /> } />
			<Route exact path='/games/junglejive' element={ <JungleJive /> } />
			<Route exact path='/games/stickyfeet' element={ <StickyFeet /> } />
			<Route exact path='/games/readyyetigo' element={ <ReadyYetiGo /> } />
			<Route exact path='/games/theawakening' element={ <TheAwakening /> } />
			<Route exact path='/games/thebigfive' element={ <TheBigFive /> } />
			<Route exact path='/games/romeoandjuliet' element={ <RomeoAndJuliet /> } />
			<Route exact path='/games/tarzan' element={ <Tarzan /> } />
			<Route exact path='/games/lobsterpots' element={ <LobsterPots /> } />
			<Route exact path='/games/riseofthepharaohs' element={ <RiseOfThePharaohs /> } />
			<Route exact path='/games/raidersofthesea' element={ <RaidersOfTheSea /> } />
			<Route exact path='/games/wildlionsuperreels' element={ <WildLionSuperReels /> } />
			<Route exact path='/games/blaznbuffalo' element={ <BlaznBuffalo /> } />
			<Route exact path='/games/goldensabre' element={ <GoldenSabre /> } />
			<Route exact path='/games/spincarnival' element={ <SpinCarnival /> } />
			<Route exact path='/games/golddiggin' element={ <GoldDiggin /> } />
			<Route exact path='/games/twocanparty' element={ <TwoCanParty /> } />
			<Route exact path='/games/stackedcats' element={ <StackedCats /> } />
			<Route exact path='/games/wayofthetiger' element={ <WayOfTheTiger /> } />
			<Route exact path='/games/jackpothits' element={ <JackpotHits /> } />
			<Route exact path='/games/mightymedusa' element={ <MightyMedusa /> } />
			<Route exact path='/games/powerpets' element={ <PowerPets /> } />
			<Route exact path='/games/nightinegypt' element={ <NightInEgypt /> } />
			<Route exact path='/games/itsalive' element={ <ItsAlive /> } />
			<Route exact path='/games/danceofthedead' element={ <DanceOfTheDead /> } />
			<Route exact path='/games/goldenelephant' element={ <GoldenElephant /> } />
			<Route exact path='/games/sirenofthedeep' element={ <SirenOfTheDeep /> } />
			<Route exact path='/games/moonlightpanda' element={ <MoonlightPanda /> } />
			<Route exact path='/games/royaldragon' element={ <RoyalDragon /> } />
			<Route exact path='/games/montezumasquest' element={ <MontezumasQuest /> } />
			<Route exact path='/games/wolfpack' element={ <WolfPack /> } />
			<Route exact path='/games/winningbull' element={ <WinningBull /> } />
			<Route exact path='/games/unicorndreams' element={ <UnicornDreams /> } />
			<Route exact path='/games/thelastdynasty' element={ <TheLastDynasty /> } />
			<Route exact path='/games/africanrhino' element={ <AfricanRhino /> } />
			<Route exact path='/games/jackandthegiant' element={ <JackAndTheGiant /> } />
			<Route exact path='/games/happylucky' element={ <HappyLucky /> } />
			<Route exact path='/games/thunderinghearts' element={ <ThunderingHearts /> } />
			<Route exact path='/games/goldtrain' element={ <GoldTrain /> } />
			<Route exact path='/games/cosmonaut' element={ <Cosmonaut /> } />
			<Route exact path='/games/sinbadsfortune' element={ <SinbadsFortune /> } />
			<Route exact path='/games/skypirates' element={ <SkyPirates /> } />
			<Route exact path='/games/thelostidol' element={ <TheLostIdol /> } />
			<Route exact path='/games/rivernblues' element={ <RiverNBlues /> } />
			<Route exact path='/games/morecandy' element={ <MoreCandy /> } />
			<Route exact path='/games/navajolegend' element={ <NavajoLegend /> } />
		</Routes>
	</Suspense>
);

export default Router;